<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                            >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card color="blue lighten-5">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="office" 
                                    :items="offices" 
                                    default="" 
                                    item-value="office_id" 
                                    item-text="office" 
                                    label="Office ID"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            solo
                                            v-model="date"
                                            label="Period"
                                            persistent-hint
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-2"
                                            hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        type="month"
                                        no-title
                                        @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="1">
                                <v-btn class="mt-3 pa-0" 
                                color="info" 
                                elevation="2" 
                                large
                                block
                                @click="getFilteredEvents()">Search</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12 mt-2">
                                <v-sheet height="600">
                                    <v-calendar
                                    ref="calendar"
                                    v-model="value"
                                    color="primary"
                                    :events="events"
                                    :event-color="getEventColor"
                                    :type="type"
                                    @click:event="showEvent"
                                    @click:more="viewDay"
                                    @change="updateRange"
                                    ></v-calendar>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1100px">
                <v-row>
                <v-col class="col-12" sm="12" md="12">
                <v-card outlined>
                    <v-toolbar
                        :color="selectedEvent.color"
                        dark
                        >
                        <v-card-title>Detail Report
                        <v-spacer></v-spacer>
                            </v-card-title>
                            <v-toolbar-title></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                                <v-icon>mdi-heart</v-icon>
                            </v-btn>
                    </v-toolbar>
                            <v-card class="mt-2">   
                                <v-card-text class="cyan lighten-5">
                                    <h6 style="font-weight: 600;" class="cyan-text text-darken-3 m-0"><v-icon color="success darken-2">mdi-arrow-down-bold-box-outline</v-icon>Income</h6>
                                </v-card-text>
                                    <v-list class="transparent">
                                        <v-list-item style="min-height:30px">
                                            <v-col cols="12">   
                                        <v-data-table
                                            :headers="headersincome"
                                            :items="incomeDetails"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            page-count="10"
                                            class="elevation-1"
                                            >        
                                        </v-data-table>
                                        </v-col>
                                        </v-list-item>
                                    </v-list>
                            </v-card>
                    </v-card>
                        <v-card class="mt-4">   
                                <v-card-text class="orange lighten-5">
                                    <h6 style="font-weight: 600;" class="cyan-text text-darken-3 m-0"><v-icon color="orange darken-2">mdi-arrow-up-bold-box-outline</v-icon>Cost</h6>
                                </v-card-text>
                                    <v-list class="transparent">
                                        <v-list-item style="min-height:30px">
                                            <v-col cols="12">   
                                        <v-data-table
                                            :headers="headersexpense"
                                            :items="expenseDetails"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            page-count="10"
                                            class="elevation-1"
                                            >        
                                        </v-data-table>
                                        </v-col>
                                        </v-list-item>
                                    </v-list>
                            </v-card>
                              <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            </v-card-actions>
                    </v-col>
                </v-row>
            </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 export default {
    data() {
        return {
             search: '',
             selectedEventDetails: null,
             loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'Griya Kusuma',
                    disabled: false,
                    href: '/admin/gk',
                    },
                    {
                    text: 'Information',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Hotel Occupancy',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                offices:[],
                office:'',
                dialog: false,
                loading3: false,
                details:[],
                incomeDetails: [],
                expenseDetails: [],
                headersincome:[
                    {
                    text: 'Office ID',
                    align: 'start',
                    sortable: true,
                    value: 'office_id', 
                    align:'center'},
                    // { text: 'Inv ID', value: 'inv_id' , align:'center'},
                    { text: 'Doc ID', value: 'doc_id' , align:'left'},
                    { text: 'Tr_Type', value: 'tr_type' , align:'left'},
                    { text: 'Description', value: 'description' , align:'left'},
                    { text: 'Item', value: 'item' , align:'left'},
                    { text: 'Remark', value: 'remark' , align:'left'},
                      { text: 'Date From', value: 'date_from' , align:'center'},
                    { text: 'Date To', value: 'date_to' , align:'left'},
                    { text: 'Period', value: 'period' , align:'center'},
                    
                ],
                headersexpense:[
                    {
                    text: 'Office ID',
                    align: 'start',
                    sortable: true,
                    value: 'office_id', 
                    align:'center'},
                    // { text: 'Inv ID', value: 'inv_id' , align:'center'},
                    { text: 'Doc ID', value: 'doc_id' , align:'left'},
                    { text: 'Tr_Type', value: 'tr_type' , align:'left'},
                    { text: 'Description', value: 'description' , align:'left'},
                    { text: 'Item', value: 'item' , align:'left'},
                    { text: 'Remark', value: 'remark' , align:'left'},
                      { text: 'Date From', value: 'date_from' , align:'center'},
                    { text: 'Date To', value: 'date_to' , align:'left'},
                    { text: 'Period', value: 'period' , align:'center'},
                    
                ],
                offices: [{
                office_id : 'Griya Kusuma Indah',
                office : 'Griya Kusuma Indah'
                }],
                office: 'Griya Kusuma Indah',
                modal: false,
                date: '',
                weekday: [0, 1, 2, 3, 4, 5, 6],
                mode: 'stack',
                type: 'month',
                events: [],
                value: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'grey darken-1'],
                names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
                search: '',
                tr_type:'',
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
            }
        },
    mounted(){
        this.$refs.calendar.checkChange()
        },
    methods:{
        viewDay ({ date }) {
            this.value = date
            this.type = 'day'
        },
        getEventColor (event) {
        //console.log(`Event period: ${event.period}`);
        return event.color
      },

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

   async showEvent({ nativeEvent, event }) {
        console.log(event)
        this.$store.dispatch('setOverlay', true)
        this.office_id = event.office_id
        this.doc_id = event.doc_id
        this.inv_id = event.inv_id
        this.period = event.period
        this.tr_type = event.tr_type
        const eventId = event.id;
        await axios.get(`${process.env.VUE_APP_URL}/api/griya/daily_trans/GetDetailCalender?search=${this.$store.state.text}&office_id=${this.office_id ? this.office_id : ""}&doc_id=${this.doc_id ? this.doc_id : ""}&inv_id=${this.inv_id ? this.inv_id : ""}&period=${this.period ? this.period : ""}&tr_type=${this.tr_type ? this.tr_type : ""}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            
        .then(response => {
           this.$store.dispatch('setOverlay', false)
            console.log(response.data.data);
            this.incomeDetails = response.data.data.filter(detail => detail.tr_type === 'I');
            this.expenseDetails = response.data.data.filter(detail => detail.tr_type === 'O');
            this.details = response.data.data
            const eventDetails = response.data.data;
            this.selectedEventDetails = eventDetails;
            this.selectedOpen = true;
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            this.dialog = true;
            })
            .catch(error => {
            console.error(error);
            });
            nativeEvent.stopPropagation();
        },
    
    async getFilteredEvents() {
            const filtered = this.events.filter((event) => {
            const searchRegex = new RegExp(this.search, 'i');

            return event.period === this.selectedPeriod && searchRegex.test(event.remark);
            
            });
                this.value = new Date(this.date);
                return filtered;
            },

    async updateRange({ start, end }) {
        try {
            console.log(start, end);
             this.$store.dispatch('setOverlay', true)
            this.events = []
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/griya/daily_trans/GetDataCalender`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` },
            });
            this.events = response.data.data.map((event, index) => ({
                id: index,
                name: event.remark,
                office_id: event.office_id,
                doc_id: event.doc_id,
                tr_type: event.tr_type,
                inv_id:event.inv_id,
                date_from: event.date_from,
                date_to: event.date_to,
                start: event.date_from,
                end: event.date_to,
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                period: event.period,
                remark: event.remark,
                timed: true,
            }));
            this.$store.dispatch('setOverlay', false)
            this.$refs.calendar.checkChange()
        console.log(this.events)
        } catch (error) {
            console.log(error);
        }

    },
    close() {
        this.dialog = false
    },

    }

}
</script>

<style>

</style>